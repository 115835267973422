@font-face {
  font-family: 'SharpSans';
  src: url('../fonts/SharpSans/SharpSans-Bold.woff') format('woff2'),
    url('../fonts/SharpSans/SharpSans-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SharpSans';
  src: url('../fonts/SharpSans/SharpSans-Semibold.woff2') format('woff2'),
    url('../fonts/SharpSans/SharpSans-Semibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SharpSans';
  src: url('../fonts/SharpSans/SharpSans-Book.woff2') format('woff2'),
    url('../fonts/SharpSans/SharpSans-Book.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SharpSans';
  src: url('../fonts/SharpSans/SharpSans-Medium.woff2') format('woff2'),
    url('../fonts/SharpSans/SharpSans-Medium.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SharpSans';
  src: url('../fonts/SharpSans/SharpSans-Light.woff2') format('woff2'),
    url('../fonts/SharpSans/SharpSans-Light.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'PlusJakartaSans';
  src: url('../fonts/PlusJakartaSans/PlusJakartaSans-Bold.woff2')
    format('woff2');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'PlusJakartaSans';
  src: url('../fonts/PlusJakartaSans/PlusJakartaSans-Medium.woff2')
    format('woff2');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'PlusJakartaSans';
  src: url('../fonts/PlusJakartaSans/PlusJakartaSans-Regular.woff2')
    format('woff2');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@500;600;700&display=swap');

html,
body {
  font-family: 'SharpSans', 'Manrope', -apple-system, 'Helvetica Neue',
    sans-serif;
  font-size: 10px;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  scroll-behavior: smooth;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

@import url(https://cdn.jsdelivr.net/npm/@loadsmart/miranda-tokens@2.2.0/dist/tokens/web/loadsmart/variables.css);
